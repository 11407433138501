<template>
	<div class="auth-wrapper auth-v1 px-2">
		<div class="auth-inner py-2">
			<!-- Login v1 -->
			<b-card class="mb-0">
				<b-link class="brand-logo align-start">
					<b-img
						class=""
						src="@/assets/images/logo/logoAbaOnline.jpeg"
						fluid
						alt="logo"
						style="max-width:200px"
					/>
				</b-link>

				<b-card-title class="mb-1 text-center">
					Bienvenue sur ABA-online ! 👋
				</b-card-title>
				<b-card-text class="mb-2 text-center">
					Identifiez-vous et commencez l'aventure
				</b-card-text>

				<!-- form -->
				<validation-observer ref="loginForm" #default="{invalid}">
					<b-form class="auth-login-form mt-2" @submit.prevent>
						<!-- email -->
						<b-form-group label-for="email" label="Email">
							<validation-provider
								#default="{ errors }"
								name="Email"
								rules="required|email"
							>
								<b-form-input
									id="email"
									name="login-email"
									:state="errors.length > 0 ? false : null"
									placeholder="john@example.com"
									autofocus
									v-model="signin.email"
								/>
								<small class="text-danger">L'adresse email est requise</small>
							</validation-provider>
						</b-form-group>

						<!-- password -->
						<b-form-group>
							<div class="d-flex justify-content-between">
								<label for="password">Mot de passe</label>
							</div>
							<validation-provider
								#default="{ errors }"
								name="Password"
								rules="required"
							>
								<b-input-group
									class="input-group-merge"
									:class="errors.length > 0 ? 'is-invalid' : null"
								>
									<b-form-input
										id="password"
										:type="passwordFieldType"
										class="form-control-merge"
										:state="errors.length > 0 ? false : null"
										name="login-password"
										placeholder="Mot de passe"
										v-model="signin.password"
									/>

									<b-input-group-append is-text>
										<feather-icon
											class="cursor-pointer"
											:icon="passwordToggleIcon"
											@click="togglePasswordVisibility"
										/>
									</b-input-group-append>
								</b-input-group>
								<small class="text-danger">Mot de passe obligatoire</small>
							</validation-provider>
						</b-form-group>

						<!-- checkbox -->

						<!-- submit button -->
						<b-button
							variant="primary"
							type="submit"
							block
							:disabled="invalid"
							@click="connect()"
						>
							Se connecter
						</b-button>
					</b-form>
				</validation-observer>
			</b-card>
			<!-- /Login v1 -->
		</div>
	</div>
</template>

<script>
import { ValidationProvider, ValidationObserver } from "vee-validate";
import {
	BButton,
	BForm,
	BFormInput,
	BFormGroup,
	BCard,
	BLink,
	BCardTitle,
	BCardText,
	BInputGroup,
	BInputGroupAppend,
	BImg,
} from "bootstrap-vue";

import { togglePasswordVisibility } from "@core/mixins/ui/forms";
import { Admin } from "@/mixins/admin.js";
import { speakerAbility, adminAbility } from "@/libs/acl/config";

export default {
	components: {
		BButton,
		BForm,
		BFormInput,
		BFormGroup,
		BCard,
		BCardTitle,
		BLink,
		BCardText,
		BInputGroup,
		BInputGroupAppend,
		ValidationProvider,
		ValidationObserver,
		BImg,
	},
	mixins: [togglePasswordVisibility, Admin],
	data() {
		return {
			signin: { email: "", password: "" },
		};
	},
	computed: {
		passwordToggleIcon() {
			return this.passwordFieldType === "password" ? "EyeIcon" : "EyeOffIcon";
		},
	},

	methods: {
		async connect() {
			let loader = this.$loading.show({
				container: this.$refs.formContainer,
				canCancel: true,
			});
			try {
				let response = await this.siginInAdmin(this.signin);
				if (response.status == 1) {
					await this.$store.dispatch("user/setUser", {
						payload: response.datas,
					});
					if (response.datas.user.role == 1) {
						response.datas.user.ability = adminAbility;
						this.$ability.update(adminAbility);
					} else {
						response.datas.user.ability = speakerAbility;
						this.$ability.update(speakerAbility);
					}
					localStorage.setItem("userData", JSON.stringify(response.datas.user));
					localStorage.setItem(
						"accessToken",
						JSON.stringify(response.datas.token)
					);
					window.location.href = "/admin/webinar/list";
				} else {
					this.$sweetError("Mot de passe ou adresse email incorrect");
				}
				loader.hide();
			} catch {
				loader.hide();
				this.$sweetError("Mot de passe ou adresse email incorrect");
			}
		},
	},
};
</script>

<style lang="scss">
@import "@core/scss/vue/pages/page-auth.scss";
</style>
